button, a {

    &:focus {
        outline: none;
    }

    &::-moz-focus-inner {
        border: 0;
    }
}

.ts-button {
    position: relative;
    display: inline-block;
    color: #fff !important;
    font-size: 1rem;
    font-weight: 700;
    text-decoration: none;
    background-color: $primary-color;
    border: none;
    border-radius: 0;
    box-shadow: none;
    white-space: nowrap;
    cursor: pointer;
    padding: 1.2rem 2.5rem;

    &:hover,
    &:focus {
        text-decoration: none;
        outline: none;
        opacity: .9;
    }

    &.send {
        opacity: .5 !important;
        pointer-events: none !important;
    }
}

.alm-btn-wrap {
    text-align: center;

    .ts-button {
        margin-top: 2.5rem;

        &.done {
            display: none;
        }
    }
}