footer.ts-footer {
	position: relative;
	z-index: 1;
	background-color: $primary-color;

	.footer-content {
		margin-left: -1px;
		width: calc(100% + 1px);

		.contact {
			flex-direction: column;
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
			border-left: 1px solid rgba(0, 0, 0, 0.2);

			> .item {
				height: 100%;
				width: 100%;
				padding: 3.9rem 1rem 2.3rem;

				img {
					height: 2.25rem;
				}
				
				.trace {
					margin: 10px auto 10px auto;
					width: 2px;
					height: 1.8rem;
					background: linear-gradient(to top, transparent, #fff);
				}

				h2 {
					font-size: 2.25rem;
					font-weight: 400;
				}
				
				p {
					font-size: 1.1rem;
					opacity: 50%;
					margin: 1rem auto 0;

					@media(min-width: $small) {
						max-width: 268px;
					}
				}
			}
		}
	}

	.copy-content {
		padding: 0 1.8rem 1.8rem;

		p, a {
			font-size: 0.875rem;
		}

		.copyright {
			margin: 1.8rem 0 0;
		}
	
		.copy-brand {
			margin: 1.8rem 0 0;
			
			.brand {
				position: relative;
				display: inline-block;
				height: 24px;
				
				.logo {
					height: 24px;
		
					p {
						font-size: .9em;
						height: 24px;
						text-align: right;
						line-height: 24px;
						overflow: hidden;
						transition: all .3s linear;
		
						@media(min-width: $large) {
							opacity: 0;
							width: 0;
						}
					}
					
					img {
						margin-left: .5em;
						height: 24px;
					}
				}
		
				+ .brand {
					margin-left: 1em;
				}
				
				@media(min-width: $large) {
					&:hover .logo p {
						opacity: 1;
						width: 120px;
					}
		
					+ .brand {
						margin-left: .5em;
					}
				}
			}
		}
  	}

	p, a, h2 {
		color: $secondary-color;
	}
}

.cc-window {
	z-index: 1000;
	font-size: 1em;
	padding: 1.2em !important;
	max-width: 348px !important;

	a.cc-link {
		color: #fff !important;
		padding: 0;
		text-decoration: underline !important;
	}

	.cc-btn {
		font-size: 1em !important;
		border: 0 !important;
		padding: 0.8em !important;
		font-weight: 400 !important;

		&:hover {
		background-color: $primary-color !important;
		opacity: 0.9;
		}
	}
}