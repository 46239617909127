.ts-opportunity-list {

    .item {

        a {
            display: block;
            max-width: 400px;
            margin: 0 auto;
            position: relative;

            .img {
                position: relative;
                padding-bottom: 100%;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
            }

            .text {

                h3 {
                    font-size: 1.1rem;
                    margin-top: 1.4rem;
                }

                .desc {
                    margin-top: 1.1rem;
                }

                .price {
                    margin-top: 1.1rem;
                    color: $primary-color;
                    font-size: 1.5rem;
                    font-weight: 700;
                }
            }
        }
    }
}

.ts-single-opportunity {
    
    .single-content {

        .slide-gallery {
            position: relative;

            .img {
                max-width: 670px;
                display: block;
                position: relative;
                padding-bottom: 73%;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
            }

            .owl-nav {
                height: 0;

                button {
                    position: absolute;
                    height: 2.5rem;
                    top: calc(50% - 1.25rem);

                    &.disabled {
                        display: none;
                    }

                    &.owl-prev {
                        left: .5rem;
                    }

                    &.owl-next {
                        right: .5rem;
                    }
                }
            }
        }

        .price {
            font-weight: 700;
            font-size: 3rem;
        }

        h2 {
            font-size: 1.5rem;
        }

        .desc {
            max-width: 440px;
            margin-top: 1rem;
        }
    }
}