.ts-gutter-t {
	margin-top: 3.75rem;
}
.ts-gutter-t-small {
	margin-top: 1.8rem;
}

main {
	position: relative;
}

.ts-page-header {
	background-color: $primary-color;
	padding: 3.75rem 0;

	.h-title {
		font-size: 3.5rem;
		color: $secondary-color;
	}

	.h-text {
		color: $secondary-color;
		max-width: 570px;
	}
}

.ts-title {
	font-size: 3rem;
}

.ts-text {
	opacity: 50%;
}

.ts-block {
	max-width: 970px;
	margin: 0 auto;
}

.format-text {

	h1, h2, h3, h4, h5, h6 {
		font-size: 1.7rem;
		font-weight: 500;
		margin-top: 2rem;
	}

	p, li {
		font-size: 1rem;
	}

	a {
		text-decoration: underline !important;
	}

	ol, ul {
		margin: 0;
		padding-left: 1.1rem;

		li::marker {
			color: $primary-color;
		}

		li + li {
			margin-top: 1.1rem;
		}
	}

	ul {
		list-style: disc;
	}

	* + ul, * + ol {
		margin-top: 2rem;
	}
}

.ts-filters {
	padding-bottom: 2.5rem;

	select {
		width: 270px;
		max-width: 100%;
		padding: 0 0 .5rem;
		margin-bottom: 2.5rem;
		background-image: url('../images/arrow-select.png');
		background-repeat: no-repeat;
		background-position: 100% top;
		background-size: 1.5rem;
		-webkit-appearance: none;
		-moz-appearance: none;
		font-size: 1.1rem;
		font-weight: 700;
		border: none;
		border-bottom: 2px solid rgba(#000, 0.5);
	}
}

.ts-page-contact {
	
	.contact-form {
		max-width: 770px;
	}
}

.ts-banner {
	position: relative;

	.slide picture img, .slide::after, .content {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
	}

	.slide::after {
		content: '';
		  background-image: linear-gradient(to bottom, transparent, $primary-color);
		z-index: 1;
	}

	.slide-img {
		position: relative;
		min-height: 600px;

		@media(min-width: $large) {
			min-height: 900px;
		}
	}

	.content {
		padding-top: 26px;
		height: calc(100% - 26px);
		z-index: 2;

		.texts {
			max-width: 970px;
			margin: 0 auto;
		
			* {
				color: #fff;
			}
		
			.title {
				font-size: 3.5em;
				font-weight: 700;
			}
		}
	}

	.owl-carousel .owl-dots {
		z-index: 5;
		position: absolute;
		bottom: 20px;
		width: 100%;
		height: 6px;
		display: flex;
		justify-content: center;
		align-items: end;

		button {
			margin: 0;
			padding: 0;
			border: 0;
			background: #fff;
			height: 3px;
			width: 1.8rem;
			opacity: 0.5;
			transition: all .5s linear;

			&.active {
				height: 6px;
				opacity: 1;
			}

			+ button {
				margin-left: .6rem;
			}
		}
	}
}

.ts-contact {
	background: $primary-color;
	padding-bottom: 2.5rem;
	padding-top: 1.5rem;

	.item {
		padding-top: 1rem;

		img {
			width: 1.2rem;
			margin-right: .6rem;
		}

		p {
			color: #fff;
		}
	}
}

.ts-gallery {

	.images {
		padding-bottom: 5rem;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: calc(50% + 2.5rem);
			background-color: rgba(#000, .1);
		}

		.col-imgs {
			@media(min-width: $large) {
				flex: 0 0 20%;
    			max-width: 20%;
			}

			.img {
				display: block;
                position: relative;
                padding-bottom: 100%;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
			}
		}

		+ .numbers {
			padding-top: 0;
		}
	}

	.numbers {
		padding-top: 6.25rem;
		background-color: rgba(#000, .1);
		padding-bottom: 3.75rem;

		.item {
			padding-bottom: 2.5rem;
			position: relative;

			.ts-title {
				font-weight: 700;
			}

			.text {
				color: $primary-color;
				font-size: 1.5rem;
				margin-top: .5rem;
			}

			+ .item {

				&:nth-of-type(3)::before {
					display: none;
				}

				&::before {
					@media(min-width: $medium) {
						content: '';
						position: absolute;
						background-image: url('../images/line.svg');
						background-repeat: no-repeat;
						background-size: 100% auto;
						background-position: center;
						top: 0;
						left: -1px;
						width: 2px;
						height: calc(100% - 2.5rem);
					}
					@media(min-width: $x-large) {
						display: block !important;
					}
				}
			}
		}
	}
}