.ts-page-blog {

    .blog-list {

        .pagination {
            justify-content: center;
            margin-top: 3.7rem;

            h2 {
                display: none;
            }

            .page-numbers {
                cursor: pointer;
                padding: 0 .5rem;

                &.current {
                    font-weight: 700;
                }
            }
        }
    }
}

.ts-list-row {
    margin-top: -2.5rem;

    .item {
        margin-top: 2.5rem;
    }
}

.ts-blog-list {

    .item {

        a {
            display: block;
            max-width: 400px;
            margin: 0 auto;
            position: relative;

            .img {
                position: relative;
                padding-bottom: 100%;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
            }

            .text {
                position: absolute;
                width: 100%;
                min-height: 50%;
                bottom: 0;
                left: 0;
                padding: 1.5rem;
                background-image: url('../images/blog-bg.svg');
                background-repeat: no-repeat;
                background-size: cover;
                transition: opacity .5s linear;

                @media(min-width: $large) {
                    opacity: 0;
                }

                h3 {
                    font-size: 1.1rem;
                    color: $secondary-color;
                }

                .date {
                    color: $secondary-color;
                    opacity: .5;
                    margin-top: 1.2rem;
                }
            }

            &:hover .text {
                opacity: 1;
            }
        }
    }
}

.ts-single-blog {
    
    .single-content {

        h2 {
            font-weight: 500;
            font-size: 3.5rem;
        }

        .date {
            margin-top: 1.5rem;
            color: $primary-color;
            font-size: .95rem;
        }

        .text {
            max-width: 770px;
            margin-top: 5rem;
        }
        
        .format-text {
        
            p, li {
                font-size: 1.1rem;
            }
        }

        .blog-share {

            img {
                height: 1.5rem;
            }
        }
    }
}

.ts-post-related {
    .ts-list-row {
        margin-top: 0;
    }
}