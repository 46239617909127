@import 'abstracts/variables';
@import 'abstracts/mixins';

@import 'layout/footer';
@import 'layout/forms';

@import 'components/menu-modal';
@import 'components/buttons';

@import 'pages/pages';
@import 'pages/home';
@import 'pages/about';
@import 'pages/services';
@import 'pages/blog';
@import 'pages/opportunities';
@import 'pages/portfolio';
@import 'pages/releases';

@import 'plugins/fancybox';
@import 'plugins/owl-carousel';

.no-critical {
    opacity: 1;
}