$font-primary: 'Outfit', sans-serif;

$color-body: #000000;
$primary-color: #2F9036;
$secondary-color: #ffffff;
$gray-color: #BEBEBE;
$gray-secondary-color: #707070;

//breakpoints:
$small: 576px;
$medium: 768px;
$large: 992px;
$x-large: 1200px;