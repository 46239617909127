.ts-releases-list {

	@media(min-width: $small) {
		padding: 0 15px;
	}

    .item {

        a {
            max-width: 450px;

            .img {
                padding-bottom: 133%;
            }

            .text {

                h3 {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

.ts-releases-single {

	.release-desc {

        .format-text p {
            opacity: .5;
        }
    }

    .release-blocks {

        .titles {
            border-bottom: 2px solid rgba(black, 0.2);
            margin-top: 1.875rem;

            .item {
                padding-top: 1.875rem;
                margin-bottom: -1px;
                cursor: pointer;

                p {
                    font-size: 1.1rem;
                    margin: 0 1.25rem;
                    opacity: .5;
                    padding-bottom: .5rem;
                }

                &.active p {
                    opacity: 1;
                    color: $primary-color;
                    border-bottom: .5rem solid $primary-color;
                }
            }
        }
    }

    .release-videos {

        .item-list {
            padding-top: 1.875rem;
        }

        .item {
            margin-top: 1.875rem;

            a {
				display: block;
                max-width: 380px;
            }

            .img {
                position: relative;
                padding-bottom: 55%;

                .main-img, .play {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }

                .play img {
                    height: 4.3rem;
                }
            }
        }
    }

    .release-status {

        .item-list {
            padding-top: 1.875rem;
        }

        .item {
            margin-top: 1.875rem;

            span {
                text-transform: uppercase;
                font-size: .95rem;
                font-weight: 600;

                &.text {
                    color: $primary-color;
                }
            }

            .ts-progress {
                margin-top: .6rem;
                background-color: #E5E5E5;

                > div {
                    height: 10px;
                    background: $primary-color;
                }
            }
        }
    }
}