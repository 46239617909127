.ts-page-home {

    .ts-banner {

        .slide::after {
            display: none;
        }

        .slide-img::after {
            content: '';
            background-image: linear-gradient(to bottom, transparent, $primary-color);
            z-index: 1;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }

    .home-releases {
		position: relative;
        padding-top: 5rem;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 50%;
			background-color: $primary-color;
		}

        .ts-title {
            color: #fff;
        }

        .ts-releases-list .col-item {
            max-width: 100%;
            flex: none;
            padding: 0;
        }

		@media(min-width: $medium) {
			.ts-releases-list .owl-item:nth-of-type(even) {
                margin-top: 2.5rem;
			}
		}
    }

    .home-services {
        
        @media(min-width: $small) {
            padding: 0 15px;
        }

        .item-list {

            .col-custom {
        
                @media(min-width: 1600px) {
                    flex: 0 0 25%;
                    max-width: 25%;
                }
            }

            .item {
                height: calc(100% - 5.5rem);
                margin-top: 5.5rem;
                padding: 0 3.75rem 3.75rem;
                position: relative;
                transition: all .3s linear;
                background: $primary-color;
                border: 2px solid $primary-color;

                .content {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    transition: all .3s linear;

                    h3 {
                        font-size: 1.5rem;
                    }

                    opacity: 0;
                }

                .content-hover {
                    opacity: 1;
                    transition: all .3s linear;

                    .img {
                        position: relative;
                        width: 7.5rem;
                        height: 7.5rem;
                        margin-top: -3.75rem;
                        box-shadow: 0 10px 20px rgba(#000, .16);

                        img {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            object-fit: cover;
                        }
                    }

                    p {
                        color: #fff;
                    }

                    .title {
                        margin-top: 3.75rem;
                        font-size: 1.5rem;
                        font-weight: 700;
                    }

                    .text {
                        margin-top: 1.8rem;
                    }

                    .detail {
                        height: 2px;
                        background: #fff;
                        width: 7.5rem;
                        margin: 3.75rem auto 0;
                    }
                }

                @media(min-width: $large) {
                    background: #fff;
                    border-color: #BEBEBE;

                    .content {
                        opacity: 1;
                    }

                    .content-hover {
                        opacity: 0;
                    }

                    &:hover {
                        background: $primary-color;
                        border-color: $primary-color;
    
                        .content {
                            opacity: 0;
                        }
    
                        .content-hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .home-blog {

        .col-posts {

            @media(min-width: $large) {
                width: 650px;
                max-width: 50%;
            }

            .slide-blog {
                max-width: 440px;
                transform: rotate(180deg);

                .owl-stage-outer {
                    width: 300%;
                }

                .owl-item {
                    transform: rotate(180deg);
                }

                .owl-item > div {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 0;

                    .item a {
                        max-width: 440px;
                    }
                }
            }
        }

        .col-texts {

            @media(min-width: $large) {
                width: 470px;
                max-width: 50%;
            }

            .text {

                .link {
                    color: $primary-color;
                    font-weight: 700;
                    font-size: 1.1rem;
                }

                + .text {
                    position: relative;
                    margin-top: 2.5rem;
                    padding-top: 2.5rem;

                    &::before {
                        content: '';
						position: absolute;
						background-image: url('../images/line-home.svg');
						background-repeat: no-repeat;
						background-size: 100%;
						background-position: center;
						top: 0;
						left: 0;
						width: 100%;
                        height: 2px;
                    }
                }
            }

            .text-featured {

                p {
                    font-size: 1.5rem;
                    font-weight: 700;
                }

                h3 {
                    font-size: 1rem;
                    font-weight: 400;
                    color: $primary-color;
                    margin: 1.1rem 0;
                }
            }
        }
    }
}