.ts-single-portfolio {
    
    .single-content {

        .info {

            .title {
                font-size: 1.5rem;
                font-weight: 700;
            }

            + .info {
                margin-top: 1.8rem;
            }
        }

        .slide-gallery {
            position: relative;

            .img {
                max-width: 870px;
                display: block;
                position: relative;
                padding-bottom: 73%;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
            }

            .owl-nav {
                height: 0;

                button {
                    position: absolute;
                    height: 2.5rem;
                    top: calc(50% - 1.25rem);

                    &.disabled {
                        display: none;
                    }

                    &.owl-prev {
                        left: .5rem;
                    }

                    &.owl-next {
                        right: .5rem;
                    }
                }
            }
        }

        .desc {
            max-width: 870px;
        }
    }
}