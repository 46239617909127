.ts-page-about {

	.about-values {
		
        .titles {

            .item {
                cursor: pointer;

				@media(min-width: $large) {
					+ .item {
						margin-top: 1.8rem;
					}
				}

                p {
                    font-size: 1.5rem;
					position: relative;
					transition: all .5s linear;

					&::before {
						content: '';
						position: absolute;
						left: 0;
						top: calc(50% - 2px);
						height: 4px;
						width: 0;
						background: $primary-color;
						transition: all .5s linear;
					}
                }

                &.active p {
					font-weight: 700;
					padding-left: 1.8rem;

					&::before {
						width: 1.2rem;
					}
                }
            }
        }
	}

	.about-gallery + .about-releases {
		padding-top: 1.2rem;
	}

	.about-releases {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 30%;
			background-color: rgba(#000, .1);
		}

		.ts-releases-list .col-item + .col-item {
			margin-top: 2.5rem;
		}

		@media(min-width: $medium) {
			.ts-releases-list .col-item:nth-of-type(odd) {
				margin-top: 0;
			}
		}
	}
}
