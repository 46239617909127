form, .form {

    .form-group {
        margin-bottom: 1.5rem;
    }

    input, .input,
    select,
    textarea {
        width: 100%;
        padding: 1.25rem;
        background: #fff;
        border-radius: 0;
        font-size: 0.875rem;
        font-weight: 500;
        color: $color-body;
        border: 2px solid $gray-color;

        @include placeholder {
            color: $color-body;
        }

        &[disabled] {
            cursor: no-drop;
        }
    }

    select {
        background-image: url('../images/select-bg.svg');
        background-repeat: no-repeat;
        background-size: 10px auto;
        background-position: calc(100% - 1.25rem) center;
        -webkit-appearance: none;

        option {
            font-weight: 500;
        }
    }
}