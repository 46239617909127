// Placeholder
@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }
    @include optional-at-root(':-moz-placeholder') {
        @content;
    }
    @include optional-at-root('::-moz-placeholder') {
        @content;
    }
    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}

// Reference => https://stackoverflow.com/questions/17181849/placeholder-mixin-scss-css
// Example:
// @include placeholder
// {
//     color: rgba(white, .8);
// }


// Keyframes
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

// Usage
// @include keyframes(slide-down) {
//   0% { opacity: 1; }
//   90% { opacity: 0; }
// }
// .element {
//   width: 100px;
//   height: 100px;
//   background: black;
//   @include animation('slide-down 5s 3');
// }