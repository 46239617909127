.ts-page-service {

	.item {

		.col-texts {
			width: 570px;
			max-width: 100%;

			@media(min-width: $x-large) {
				width: calc(570px + 10%);
				max-width: 50%;
				padding: 0 5%;
			}
		}

		.col-img {
			width: 800px;
			max-width: 100%;

			@media(min-width: $x-large) {
				max-width: 50%;
				padding: 0 15px;
				width: 830px;
			}

			.img {
				display: block;
                position: relative;
                padding-bottom: 75%;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
			}
		}
	}
}

.ts-default-nav, .ts-page-service .item .col-img {

	.owl-nav {

		button {
			margin: 0;
			padding: 0;
			border: 0;
			background: none;

			img {
				width: 1.5rem;
			}
		}
	}

	.owl-dots {
		height: 6px;
		display: flex;
		padding: 0 1.2rem 3px;

		button {
			margin: 0;
			padding: 0;
			border: 0;
			background: #000;
			height: 3px;
			width: 1.8rem;
			transition: all .2s linear;

			&.active {
				height: 6px;
				background: $primary-color;
			}

			+ button {
				margin-left: .6rem;
			}
		}
	}
}