.ts-mobile-menu {
	z-index: 99;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	opacity: 0;

	> .row {
		height: 100vh;
		overflow-y: auto;
	}

	.content {
		background: #ffffff;
		padding: 2rem;
		width: 390px;
		max-width: calc(100% - 15px);
		
		.btn-menu {
			background: none;
			border: none;
			padding: 0;
			margin: 0;

			img {
				height: 16px;
			}
		}

		.menu {
			margin-top: 3rem;

			a {
				display: block;
				font-weight: 500;
				font-size: 18px;

				+ a {
					margin-top: 1.5rem;
				}
			}
		}
	}

	.content-close {
		background: rgba(#000, 0.4);
		border: none;
	}
}

.open-menu .ts-mobile-menu {
	display: block;
	opacity: 1;
	@include animation('fadein .5s');
}

.overlay, .overlay body {
	overflow-x: hidden !important;
	overflow-y: hidden !important;
}

@include keyframes(fadein) {
	from { opacity: 0; }
	to   { opacity: 1; }
}